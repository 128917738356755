<!--
 * @Author: yy
 * @Date: 2021-09-29 10:42:22
 * @LastEditTime: 2021-10-12 19:58:48
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\views\setting\setting.vue
-->

<template>
  <div class="pageContent">
    <div class="navigationBar">
      <div class="topView">
        <i class="icon-arrow-left icon" @click="goBack"></i>
        <span>设置</span>
      </div>
      <div class="horizontalLine"></div>
      <div :class="selected == 1 ? 'itemView selectItemView':'itemView'" @click="change(1)">
        <i class="iconfont icon-set-user icon"></i>
        <span>账户信息</span>
        <v-icon class="rightIcon">$arrow-right</v-icon>
      </div>
      <div :class="selected == 2 ? 'itemView selectItemView':'itemView'" @click="change(2)">
        <i class="iconfont icon-version icon"></i>
        <span>版本说明</span>
        <v-icon class="rightIcon">$arrow-right</v-icon>
      </div>
      <div class="horizontalLine"></div>
      <div class="itemView" @click="logOut">
        <i class="iconfont icon-quit icon"></i>
        <span>退出登录</span>
      </div>
    </div>
    <div class="verticalLine"></div>
    <div class="detail" v-if="selected == 1">
      <div class="title marginBottom">账户信息</div>
      <div class="detailItem marginBottom">
        <span>姓名</span>
        <span>{{userInfo.name || '暂无数据'}}</span>
      </div>
      <div class="detailItem marginBottom">
        <span>性别</span>
        <span>{{userInfo.sex == 0 ? '男':'女'}}</span>
      </div>
      <div class="detailItem">
        <span>出生年月</span>
        <span>{{userInfo.dateBirth || '暂无数据'}}</span>
      </div>
      <div class="horizontalLine"></div>
      <div class="detailItem marginBottom">
        <span>学校</span>
        <span>{{userInfo.school || '暂无数据'}}</span>
      </div>
      <div class="detailItem marginBottom">
        <span>年级</span>
        <span>{{userInfo.grade || '暂无数据'}}</span>
      </div>
      <div class="detailItem marginBottom">
        <span>班级</span>
        <span>{{userInfo.class || '暂无数据'}}</span>
      </div>
      <div class="detailItem marginBottom">
        <span>学号</span>
        <span>{{userInfo.studentNumber || '暂无数据'}}</span>
      </div>
    </div>
    <div class="detail" v-if="selected == 2">
      <div class="title marginBottom">版本说明</div>
      <div class="detailItem marginBottom">
        <span>当前版本</span>
        <span>1.0.3</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selected: 1,
      userInfo: {}
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    change(value) {
      if (value != this.selected) {
        this.selected = value;
      }
    },
    getUserInfo() {
      this.$service.getUserInfo().then(res => {
        this.userInfo = res;
      });
    },
    logOut(){
      window.localStorage.clear();
      this.$router.push({
        path:'login'
      })
    }
  }
};
</script> 
<style lang="scss" scoped>
.pageContent {
  background: #fff;
  height: 100%;
}
.navigationBar {
  width: 30vw;
  float: left;
  .topView {
    height: 70px;
    line-height: 70px;
    padding: 0 40px 0 20px;
    background: #fff;
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    width: 100%;
    .icon {
      width: 30px;
      height: 30px;
      display: block;
      float: left;
    }
  }
  .itemView {
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
    background: #fff;
    .icon {
      width: 25px;
      height: 25px;
      display: block;
      float: left;
    }
    .rightIcon {
      float: right;
      display: block;
      width: 25px;
      height: 25px;
      margin: 15px 0;
    }
  }
  .selectItemView {
    color: #fff;
    background: #0148ff;
    .v-icon.v-icon {
      color: #fff;
    }
  }
}

.verticalLine {
  height: 100%;
  width: 1px;
  float: left;
  background: #eee;
}
.detail {
  float: right;
  width: calc(70vw - 1px);
  .title {
    margin: 0 auto;
    width: calc(100% - 40px);
    font-size: 1.25rem;
    font-weight: bold;
    height: 70px;
    line-height: 70px;
    background: #fff;
  }
  .detailItem {
    width: calc(100% - 40px);
    margin: 0 auto;
    background: #fff;
    height: 60px;
    line-height: 60px;
    span {
      display: block;
      float: left;
      color: #333;
    }
  }
  .detailItem span:last-child {
    float: right;
    max-width: 50vw;
    color: #999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.horizontalLine {
  height: 10px;
  background: #f0f2f5;
}
.marginBottom {
  border-bottom: 1px solid #eee;
}
</style> 

